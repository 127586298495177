/**
 * 前面补0
 * @param {*} num 数字
 * @param {*} length 长度
 */
export function PrefixIntrger(num, length) {
  return (Array(length).join("0") + num).slice(-length);
}

/**
 * 随机函数
 * @param {*} num 从0~num的随机数
 */
export function randomFunc(num) {
  return Math.round(Math.random() * num);
}

/**
 * 时间处理器
 * @param {*} date new Date
 * */

export function timeDealer(date) {
  const year = new Date(date).getFullYear().toString();
  const month =
    new Date(date).getMonth() + 1 >= 10
      ? (new Date(date).getMonth() + 1).toString()
      : "0" + (new Date(date).getMonth() + 1);
  const day =
    new Date(date).getDate() >= 10
      ? new Date(date).getDate().toString()
      : "0" + new Date(date).getDate();
  const hours =
    new Date(date).getHours() >= 10
      ? new Date(date).getHours().toString()
      : "0" + new Date(date).getHours();
  const minutes =
    new Date(date).getMinutes() >= 10
      ? new Date(date).getMinutes().toString()
      : "0" + new Date(date).getMinutes();
  const seconds =
    new Date(date).getSeconds() >= 10
      ? new Date(date).getSeconds().toString()
      : "0" + new Date(date).getSeconds();
  const str =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
  return str;
}
// 乱序数组
export function disturbList(list) {
  const result = [];
  list.forEach((item) => {
    if (result.length > 2) {
      const random = Math.floor(Math.random() * result.length);
      result.splice(random, 0, item);
    } else {
      result.push(item);
    }
  });
  return result;
}
// 动态变量的图片
export function getAssetsFile(val, suffix) {
  let files = null;
  let result = "";
  switch (suffix) {
    case "mp4": {
      files = require.context("@/assets/video", false, /\.mp4$/);
      const arr = files.keys().filter((item) => item.indexOf(val) > -1);
      const res = arr.map((item) => {
        return files(item);
      });
      result = res[0];
      break;
    }
    case "png": {
      files = require.context("@/assets/images", false, /\.png$/);
      const arr = files.keys().filter((item) => item.indexOf(val) > -1);
      const res = arr.map((item) => {
        return files(item);
      });
      result = res[0];
      break;
    }
    case "jpg": {
      files = require.context("@/assets/images", false, /\.jpg$/);
      const arr = files.keys().filter((item) => item.indexOf(val) > -1);
      const res = arr.map((item) => {
        return files(item);
      });
      result = res[0];
      break;
    }
    case "http": {
      result = val;
      break;
    }
  }

  return result;
}
